<template>
    <section class="map" id="map">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 col-md-5 col-info">
                    <h2 class="title">
                        Доставка<br>и оплата
                    </h2>
                    <div class="info">
                        <p>
                            Бесплатная доставка в пределах МКАД
                        </p>
<!--                        <div class="info__zone">-->
<!--                            <b>Зона 1</b><br>-->
<!--                            Бесплатно-->
<!--                        </div>-->
<!--                        <div class="info__zone">-->
<!--                            <b>Зона 2</b><br>-->
<!--                            350 <span class="rub">руб.</span>-->
<!--                        </div>-->
                        <p>
                            <b>
                                Временные промежутки доставки
                            </b>
                        </p>
                        <p>
                            c 7:00 до 8:00, c 8:00 до 9:00, c 9:00 до 10:00
                        </p>
                        <p class="info__text">
                            Все заказы принимаются за 24 часа! Если ваш адрес не входит в зону доставки или вы хотите выбрать другой временной промежуток, свяжитесь с нами.
                        </p>
                    </div>
                    <div class="col-12">
                        <a href="https://hgclub.ru/catalog/?select_zone=357" target="_blank" class="order">
                            Заказать доставку
                        </a>
                    </div>
                </div>
                <div class="col-sm-12 col-md-7 wrap-map">
                    <div id="maps" class="img-map" style="background-image: url('img/map.png')"></div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { loadYmap } from 'vue-yandex-maps'

    export default {
        name: "Map",
        //components: { yandexMap },
        async mounted() {
            const settings = {
                apiKey: '226b41d3-c250-4ecf-a0a0-41d8239fb833',
                lang: 'ru_RU',
                version: '2.1'
            }

            await loadYmap(settings);

            let ymaps = window.ymaps;
            ymaps.ready(init);

            function init() {
                let map = new ymaps.Map('maps', {
                    center: [55.7522, 37.6156],
                    zoom: 10,
                    controls: ['zoomControl']
                });

                ymaps.geoXml.load('geoObjects.kml').then(onGeoXmlLoad);
                function onGeoXmlLoad(res) {
                    map.geoObjects.add(res.geoObjects);
                    res.mapState.applyToMap(map);
                }
            }

        }
    }

</script>

<style lang="scss">
    .rub {
        font-family: "RUB";
    }
    .map {
        padding: 65px 15px 0;
        background: #FBFBFB;

        @media screen and (min-width: $tablet) {
            padding: 65px 0 0;
            background: transparent;
        }

        .title {
            font-weight: bold;
            font-size: 38px;
            line-height: 36px;
            padding: 0 0 0 110px;
            background: url("/img/map-icon.svg") no-repeat center left;

            @media screen and (min-width: $tablet) {
                padding: 65px 0 0 110px;
                background: url("/img/map-icon.svg") no-repeat 0% 90%;
            }
        }

        .img-map {
            background-size: cover;
            background-position: center center;
        }

        .ymap-container,
        .img-map {
            width: 100%;
            height: 350px;
            border-radius: 10px;
            overflow: hidden;

            @media screen and (min-width: $tablet) {
                height: 100%;
            }
        }

        .wrap-map {
            padding: 0;
            margin-left: -15px;
            margin-right: -15px;
            width: calc(100% + 30px);

            @media screen and (min-width: $tablet) {
                margin-left: 0;
                margin-right: 0;
                width: 100%;
                min-width: 100%;
                min-height: 350px;
                margin-bottom: 45px;
                padding: 0 15px
            }

            @media screen and (min-width: $desktop) {
                min-width: auto;
                margin-bottom: 0;
                padding-left: 0;
            }
        }

        .col-info {
            padding: 0;
            background: #FBFBFB;
            border-bottom-left-radius: 10px;
            border-top-left-radius: 10px;
            @media screen and (min-width: $tablet) {
                min-width: 360px;
                padding: 0 15px;
                margin: 0 auto
            }

            @media screen and (min-width: $desktop) {
                padding: 0 90px 0 45px;
                min-width: auto;
                margin: 0;
            }

            .info {
                padding: 25px 10px 45px;
                font-size: 18px;
                line-height: 22px;

                @media screen and (min-width: $desktop) {
                    padding: 25px 0 45px;
                }

                &__zone {
                    display: inline-block;
                    border-radius: 10px;
                    padding: 20px 15px;
                    margin-right: 30px;
                    margin-bottom: 25px;

                    &:first-child {
                        background: rgba(#FECE23, 0.3);
                    }

                    &:nth-child(2) {
                        background: rgba(#961EC0, 0.3);
                    }
                }

                &__text {
                    color: #959DA2;
                }
            }
        }

        .delivery-map {
            height: 350px;
            width: 100%;
        }

        .order {
            text-align: center;
            background-color: #4FA31F;
            border: none;
            display: block;
            margin: 0 auto;
            color: #ffffff;
            padding: 15px 25px;
            font-size: 18px;
            line-height: 17px;
            border-radius: 50px;
            text-decoration: none;
            margin-bottom: 100px;
        }
    }
</style>