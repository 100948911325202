<template>
    <section class="about" id="about">
        <div class="container">
            <div class="row">
                <div class="col-md-6" v-for="(item, index) in items" :key="index">
                    <div class="about__item">
                        <div class="about__item-icon">
                            <img :src="item.item.icon">
                        </div>
                        <p class="about__item-text">
                            {{ item.item.text }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="banner">
                        <a href="https://hgclub.ru/catalog/?brand=100000020" target="_blank">
                            <picture>
                                <source media="(min-width: 768px)" :srcset="banner.deskImg">
                                <img
                                    width="100%"
                                    class="img-fluid"
                                    :src="banner.mobileImg"
                                >
                            </picture>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "About",
        data() {
            return {
                banner: {
                    mobileImg: 'img/banner3_mobile.png',
                    deskImg: 'img/banner3.png'
                },
                items: [
                    {
                        item: {
                            icon: 'img/icon-1.svg',
                            text: 'Считаем за вас калории – просто выберите нужный рацион',
                        }
                    },
                    {
                        item: {
                            icon: 'img/icon-2.svg',
                            text: 'Обновляем меню раз в 3 недели – не успеет наскучить',
                        }
                    },
                    {
                        item: {
                            icon: 'img/icon-3.svg',
                            text: 'Экономим ваше время – не надо тратить его на готовку',
                        }
                    },
                    {
                        item: {
                            icon: 'img/icon-4.svg',
                            text: 'Вкусно готовим – у нас своя команда крутых шеф-поваров',
                        }
                    },
                    {
                        item: {
                            icon: 'img/icon-5.svg',
                            text: 'Быстро доставляем – на следующий день после заказа',
                        }
                    },
                    {
                        item: {
                            icon: 'img/icon-6.svg',
                            text: 'Подстраиваемся под вас - делаем всё, чтобы вам было удобно и вкусно',
                        }
                    },
                ]
            }
        }
    }
</script>

<style lang="scss">
    .about {
        padding: 40px 0;

        &__item {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin: 20px 0;
        }

        &__item-icon {
            width: 80px;
            height: 80px;
            margin-right: 20px;
            text-align: center;
            line-height: 80px;
        }

        &__item-text {
            width: calc(100% - 100px);
            font-weight: 500;
            font-size: 16px;
            line-height: 25px;
            letter-spacing: 0.05em;
            margin: 0;

            @media screen and (min-width: $desktop) {
                font-size: 18px;
            }
        }

        .banner {
            margin-top: 40px;
        }
    }
</style>
