<template>
    <header class="header container">
        <div class="header__logo">
            <img src="img/logo.svg" alt="logo">
        </div>
        <div class="header__navmenu">
            <a :href="'tel:' + contactLinks.phone" class="header__link-tel">
                <img src="img/phone-mobile.svg">
            </a>
            <div class="navmenu__toggle" @click="openMenu">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M31.73 7.40369H0M31.73 24.3264H12.692M31.73 15.865H19.038" stroke="#4FA31F" stroke-width="2.36"/>
                </svg>
            </div>
            <nav class="navmenu nav-js" :class="{ 'navmenu--open': isActive}">
                <div class="container">
                    <div class="navmenu__mobile-block">
                        <div class="header__logo">
                            <img src="img/logo.svg" alt="logo">
                        </div>
                        <div class="navmenu__toggle" @click="openMenu">
                            <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L26 26M1 26L26 1" stroke="#4FA31F" stroke-width="2.36"/>
                            </svg>
                        </div>
                    </div>
                    <ul class="navmenu__menu">
                        <li class="navmenu__menu-item" v-for="(item, index) in links" :key="index">
                            <a class="navmenu__menu-link js-link" :href="item.link" @click="openMenu">
                                {{ item.name }}
                            </a>
                        </li>
                    </ul>
                    <div class="navmenu__links">
                        <nav class="links">
                            <a class="links__item links__item--inst" :href="contactLinks.inst">
                                <img src="img/instagram.svg">
                                SPINACH.DELIVERY
                            </a>
                            <a class="links__item links__item--tel" :href="'tel:' + contactLinks.phone">
                                <img src="img/phone.svg">
                                {{contactLinks.phone}}
                            </a>
                        </nav>
                    </div>
                </div>
            </nav>
        </div>
    </header>
</template>

<script>
    export default {
        name: "Header",
        data () {
            return {
                isActive: false,
                links: [
                    {
                        name: 'О нас',
                        link: '#about'
                    },
                    {
                        name: 'Доставка и оплата',
                        link: '#map'
                    },
                    {
                        name: 'Примеры блюд',
                        link: '#examples'
                    },
                ]
            }
        },
        created() {
            let self = this;
            self.contactLinks = {
                phone: "8 (800) 700-66-00",
                inst: "https://www.instagram.com/shpinat.delivery/",
            };
        },
        methods: {
            openMenu: function () {
                let self = this;
                self.isActive = !self.isActive;
            }
        }
    }
</script>

<style lang="scss">
    .header {
        padding: 40px 15px 25px;
        flex: 0 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__logo {
            width: 110px;

            @media screen and (min-width: $tablet) {
                width: 140px;
            }
        }

        &__navmenu {
            display: flex;
            align-items: center;

            @media screen  and (min-width: $desktop) {
                width: 85%;
            }
        }

        &__link-tel {
            margin-right: 34px;

            @media screen and (min-width: $desktop) {
                display: none;
            }
        }
    }

    .navmenu {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        overflow-y: auto;
        background-color: #ffffff;
        transition: all .2s ease-in-out;
        z-index: -1;
        opacity: 0;
        padding: 40px 0;

        & > .container {
            @media screen and (min-width: $desktop) {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }

        @media screen and (min-width: $desktop) {
            position: static;
            height: auto;
            padding: 0;
            z-index: 1;
            opacity: 1;
        }

        &--open {
            z-index: 999;
            opacity: 1;
        }

        &__toggle {
            cursor: pointer;

            @media screen and (min-width: $desktop) {
                display: none;
            }
        }

        &__mobile-block {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media screen and (min-width: $desktop) {
                display: none;
            }
        }

        &__menu {
            padding: 85px 0;
            text-align: center;
            list-style: none;

            @media screen and (min-width: $desktop) {
                padding: 0;
                display: flex;
                margin: 0 auto;
                width: 440px;
                justify-content: space-between;
            }
        }

        &__menu-item {
            margin: 35px 0;
        }

        &__menu-link {
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: $accent-color;

            &:hover, &:active {
                color: $accent-color;
                text-decoration: none;
            }
        }

        &__links {
            .links {
                text-align: center;

                @media screen and (min-width: $desktop) {
                    display: flex;
                }

                &__item {
                    display: block;
                    margin: 20px auto;
                    font-weight: 900;
                    font-size: 18px;
                    line-height: 24px;
                    color: $accent-color;

                    &:hover, &:active {
                        color: $accent-color;
                        text-decoration: none;
                    }

                    &--inst {
                        @media screen and (min-width: $desktop) {
                            font-size: 0;
                            margin-right: 35px;
                        }
                    }
                }
            }
        }
    }
</style>