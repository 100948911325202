import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue} from 'bootstrap-vue';
Vue.use(BootstrapVue);

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.config.productionTip = false

import YmapPlugin from 'vue-yandex-maps'

const settings = {
    apiKey: '226b41d3-c250-4ecf-a0a0-41d8239fb833',
    lang: 'ru_RU',
    coordorder: 'latlong',
    version: '2.1'
}

Vue.use(YmapPlugin, settings)

new Vue({
    render: h => h(App),
}).$mount('#app')
