<template>
    <footer class="footer container">
        <div class="row">
            <div class="col-sm-12 col-md-3">
                <div class="footer__item">
                    <div class="footer__title">
                        Принимаем к оплате
                    </div>
                    <div class="footer__icons">
                        <img src="img/Apple_Pay.png">
                        <img src="img/Google_Pay.png">
                        <img src="img/Visa.png">
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="footer__item">
                    <div class="footer__title">
                        Контакты
                    </div>
                    <div class="footer__contacts">
                        <a class="footer__contacts-link" :href="'tel:' + contactLinks.phone">
                            <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.86529 0.934326H4.16659C2.32564 0.934326 0.833252 2.42671 0.833252 4.26766V7.60099C0.833252 16.8057 8.29517 24.2677 17.4999 24.2677H20.8333C22.6742 24.2677 24.1666 22.7753 24.1666 20.9343V18.6311C24.1666 17.9998 23.8099 17.4227 23.2453 17.1403L19.2187 15.1271C18.3116 14.6735 17.213 15.1285 16.8922 16.0907L16.396 17.5796C16.0745 18.544 15.0852 19.1181 14.0884 18.9187C10.0987 18.1208 6.98016 15.0022 6.18222 11.0125C5.98286 10.0157 6.55696 9.02642 7.52135 8.70496L9.36907 8.08905C10.1953 7.81365 10.6701 6.94858 10.4589 6.10369L9.4822 2.19677C9.29671 1.45482 8.63007 0.934326 7.86529 0.934326Z" stroke="black" stroke-width="1.3"/>
                            </svg>
                            {{contactLinks.phone}}
                        </a>
                        <a class="footer__contacts-link" :href="contactLinks.inst">
                            <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.3333 5.93433H19.9999M7.49992 0.934326H17.4999C21.1818 0.934326 24.1666 3.91909 24.1666 7.60099V17.601C24.1666 21.2829 21.1818 24.2677 17.4999 24.2677H7.49992C3.81802 24.2677 0.833252 21.2829 0.833252 17.601V7.60099C0.833252 3.91909 3.81802 0.934326 7.49992 0.934326ZM12.4999 17.601C9.7385 17.601 7.49992 15.3624 7.49992 12.601C7.49992 9.83957 9.7385 7.60099 12.4999 7.60099C15.2613 7.60099 17.4999 9.83957 17.4999 12.601C17.4999 15.3624 15.2613 17.601 12.4999 17.601Z" stroke="black" stroke-width="1.3"/>
                            </svg>
                            SPINACH.DELIVERY
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-3">
                <div class="footer__item footer__item--logo">
                    <a href="http://www.rosinter.ru" target="_blank">
                        <img src="img/logo-rosinter.png">
                    </a>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    export default {
        name: "Footer",
        created() {
            let self = this;
            self.contactLinks = {
                phone: "8 (800) 700-66-00",
                inst: "https://www.instagram.com/shpinat.delivery/",
            }
        },    }
</script>

<style lang="scss">
    .footer {
        flex: 0 0 auto;
        padding: 64px 15px;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 15px;
            right: 15px;
            height: 1px;
            background-color: #E9E9E9;
         }

        &__item {
            margin-bottom: 40px;

            &--logo {
                width: 150px;

                @media screen and (min-width: $desktop){
                    padding-top: 30px;
                }
            }
        }

        &__title {
            font-weight: 900;
            font-size: 18px;
            line-height: 27px;
            margin-bottom: 15px;
        }

        &__icons {
            img {
                margin-right: 15px;
            }
        }

        &__contacts-link {
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            color: #000000;
            margin-bottom: 25px;
            margin-right: 30px;
            display: inline-block;

            &:hover {
                text-decoration: none;
                color: #000000;
            }

            svg {
                margin-right: 15px;
            }
        }
    }
</style>