<template>
  <div id="app" class="page">
    <Header/>
    <main class="content">
      <MainSlider/>
      <!--<Main/>-->
      <!--<Tabs/>-->
      <Products/>
      <Map/>
      <About/>
      <Slider/>
    </main>
    <Footer/>
  </div>
</template>

<script>
import Header from './components/Header.vue';
import MainSlider from './components/MainSlider.vue'
//import Main from './components/Main.vue';
//import Tabs from "./components/Tabs";
import Products from './components/Products.vue';
import Map from './components/Map.vue';
import About from './components/About.vue';
import Slider from './components/Slider.vue';
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    MainSlider,
    //Main,
    //Tabs,
    Products,
    Map,
    About,
    Slider,
    Footer
  }
}
</script>
